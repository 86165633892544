import React from 'react';
import styled from 'styled-components';
import SEO from 'components/shared/SEO';
import Content from 'components/shared/Content';

import linkedinIcon from 'assets/icons/linkedin.svg';
import mailIcon from 'assets/icons/email-white.svg';
import Icon from 'components/shared/Icon';

import memberAvatar1 from 'assets/images/team-member-1-avatar.png';
import memberAvatar2 from 'assets/images/team-member-2-avatar.png';
import memberAvatar3 from 'assets/images/team-member-3-avatar.png';
import memberAvatar4 from 'assets/images/team-member-4-avatar.png';
import memberAvatar5 from 'assets/images/team-member-5-avatar.png';
import memberAvatar6 from 'assets/images/team-member-6-avatar.png';
import memberAvatar7 from 'assets/images/team-member-7-avatar.png';
import memberAvatar8 from 'assets/images/team-member-8-avatar.png';
import memberAvatar9 from 'assets/images/team-member-9-avatar.png';
import memberAvatar10 from 'assets/images/team-member-10-avatar.png';

const getImg = id => {
  switch (id) {
    case 1:
      return memberAvatar1;
    case 2:
      return memberAvatar2;
    case 3:
      return memberAvatar3;
    case 4:
      return memberAvatar4;
    case 5:
      return memberAvatar5;
    case 6:
      return memberAvatar6;
    case 7:
      return memberAvatar7;
    case 8:
      return memberAvatar8;
    case 9:
      return memberAvatar9;
    case 10:
      return memberAvatar10;
    default:
      return '';
  }
};

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  line-height: 1.5;

  ${({ theme }) => theme.mq.xxxl} {
    padding-top: 160px;
  }

  ${({ theme }) => theme.mq.lg} {
    min-height: calc(100vh - 75px);
    justify-content: center;
  }
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  margin: 30px 0 80px;
  padding: 0 20px;

  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    width: 90%;
    max-width: 1140px;
    margin: 0;
  }
`;

const MemberInfo = styled.div`
  ${({ theme }) => theme.mq.lg} {
    margin-right: 5rem;
  }
`;

const Avatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1.5rem;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0 1.75rem;
  ${({ theme }) => theme.mq.lg} {
    justify-content: center;
  }
`;

const IconWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0.7rem;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.darkPurple100};

  :first-child {
    margin-right: 1rem;
  }
`;

const NameText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.darkPurple100};
  line-height: 1.4;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;
const Role = styled.p`
  margin-top: 0.35rem;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Description = styled.div`
  ${({ theme }) => theme.mq.md} {
    font-size: 1.65rem;
  }

  & > :first-child {
    margin-bottom: 1.75rem;
  }
`;

const ListLabel = styled.div`
  margin: 2.5rem 0 1rem;
  ${({ theme }) => theme.mq.md} {
    font-size: 1.65rem;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListItem = styled.li`
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    min-width: 5px;
    height: 5px;
    min-height: 5px;
    border-radius: 50%;
    background-color: #000;
  }

  ${({ theme }) => theme.mq.md} {
    font-size: 1.65rem;
  }
`;

const TeamMemberLayout = ({ pageContext: { member } }) => {
  return (
    <Main>
      <SEO title={`${member.name} ${member.surname}`} />
      <StyledContent>
        <MemberInfo>
          <Avatar src={getImg(member.id)} alt="team-member" />
          <NameText> {`${member.name} ${member.surname}`} </NameText>
          <Role> {member.role} </Role>
          <Icons>
            <IconWrapper href={member.linkedin}>
              <Icon src={linkedinIcon} size={20} />
            </IconWrapper>
            <IconWrapper href="mailto:">
              <Icon src={mailIcon} size={20} />
            </IconWrapper>
          </Icons>
        </MemberInfo>
        <About>
          <Description
            dangerouslySetInnerHTML={{ __html: member.about }}
          ></Description>
          <ListLabel
            dangerouslySetInnerHTML={{
              __html: member.strengths && member.strengths.label,
            }}
          />
          <List>
            {member.strengths &&
              member.strengths.list.map(item => (
                <ListItem dangerouslySetInnerHTML={{ __html: item }} />
              ))}
          </List>
        </About>
      </StyledContent>
    </Main>
  );
};

export default TeamMemberLayout;
